html, body {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

html {
  background-color: #eeeeee;
  background-image: url(/img/pic/background.svg?v=0.001);
  background-size: 600px;
  background-position: center;
}

#root /*.wrapper*/ {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  /* background: #eeeeee; */
  /* overflow: hidden; */
  /* transition: all 1s ease;*/
}

.s1 {
  font-family: "PT Sans", sans-serif;
  font-weight: 300;
  font-size: 64px; /*4em*/
  word-break: normal;
  white-space: pre-wrap;
}

.s2 {
  font-family: Open Sans, sans-serif;
  font-weight: 300;
  font-size: 54px; /*3.3em*/
  word-break: normal;
  white-space: pre-wrap;
}

.s3 {
  font-family: Open Sans Light, sans-serif;
  font-weight: 300;
  font-size: 44px; /*3em*/
  word-break: normal;
  white-space: pre-wrap;
}

.s4 {
  font-family: Open Sans, sans-serif;
  font-weight: 300;
  font-size: 40px; /*2.5em*/
  word-break: normal;
  white-space: pre-wrap;
}

hr {
  width: 90%;
  background: #666666;
  margin: 50px auto;
}

a {
  color: #3FA9F5;
}

.vertical-align-middle {
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (orientation: landscape) {
  #root {
    zoom: 0.5;
  }
}

/*noinspection CssInvalidMediaFeature*/
@media (prefers-color-scheme: dark) {
}